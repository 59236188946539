import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken()
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token
      }
      return config;
    },
    (error) => {
      return Promise.reject(error)
    }
  );

/*   axiosInstance.interceptors.response.use(
    async (res) => {
      const originalConfig = res.config

      if (originalConfig.url !== "/auth/login/dealer" && res.data) {
        if (res.status == 401 && !originalConfig._retry) {
          originalConfig._retry = true
          try {
            const rs = await axiosInstance.post("/auth/refresh/token", {
              refreshToken: TokenService.getLocalRefreshToken()
            })
            //eslint-disable no-unreachable
            if (rs.data.detail == 'ANYWAIR INVALID ACCESS TOKEN')
              store.dispatch('auth/logout')
              return 
            const accessToken = rs.data.detail
            store.dispatch('auth/refreshToken', accessToken)
            TokenService.updateLocalAccessToken(accessToken)

            return axiosInstance(originalConfig)
          } catch (_error) {
            return Promise.reject(_error)
          }
        }
      }
      return res
    }
  ) */
}

export default setup