import AuthService from '../services/auth.service'

const user = JSON.parse((JSON.stringify)(localStorage.getItem('user')))
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  user: null,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      );
    },
    employeelogin({ commit }, employee) {
      commit('loginSuccess', employee)
    },
    setTransponder({ commit }, transponder) {
      commit('trans', transponder)
    },
    setDept({ commit }, client_dept) {
      commit('dept', client_dept)
    },
    setSubDept({ commit }, client_sub_dept) {
      commit('subDept', client_sub_dept)
    },
    setSubsidiary({ commit }, subsidiary) {
      commit('subsidiary', subsidiary)
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken)
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
      state.client_dept = 0
      state.client_sub_dept = 0
      state.subsidiary = 0
    },
    dept(state, dept) {
      state.client_dept = dept
    },
    trans(state, transponder) {
      state.transponder = transponder
    },
    subDept(state, subDdept) {
      state.client_sub_dept = subDdept
    },
    subsidiary(state, subsidiary) {
      state.subsidiary = subsidiary
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null;
      state.client_dept = 0;
      state.client_sub_dept = 0;
      state.transponder = null;
      state.subsidiary = 0;
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true
      state.user = { ...state.user, token: accessToken }
    }
  }
};
export const constants = {
  namespaced: true,
  state: {
    client_id: null,
  },
  actions: {
    setClientId({ commit }, client_id) {
      commit('commitClientId', client_id)
    }
  },
  mutations: {
    commitClientId(state, client_id) {
      state.client_id = client_id
    }
  }
};
