import { createStore } from "vuex";
import { auth } from "./auth.module";
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  modules: {
    auth,
  },
});

export default store;
