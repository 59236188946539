class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user?.refresh_token
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"))
    return user?.access_token
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("user"))
    user.access_token = token;
    localStorage.setItem("user", JSON.stringify(user))
  }

  getUser() {
    return JSON.parse(localStorage.getItem("user"))
  }
  getSubsidiaries() {
    return JSON.parse(localStorage.getItem("subsidiaries"))
  }
  getContact() {
    return JSON.parse(localStorage.getItem("contact"))
  }

  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user))
  }
  setSubsidiaries(subsidiaries) {
    localStorage.setItem("subsidiaries", JSON.stringify(subsidiaries))
  }
  setContact(contact) {
    localStorage.setItem("contact", JSON.stringify(contact))
  }
  removeUser() {
    localStorage.removeItem("user")
  }
  removeContact() {
    localStorage.removeItem("contact")
  }
}

export default new TokenService();
